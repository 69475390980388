import React from 'react'
import serverImage from '../assets/server.png'
import trainingImage from '../assets/presentation.png'
import moveImage from '../assets/transfer-data.png'
import subscribeImage from '../assets/subscription.png'
import configureImage from '../assets/settings.png'
import supportImage from '../assets/management-service.png'

const HowSection = () => {
  return (
    <div id='process' className='py-[80px] bg-cyan-800/5'>
           <div className='w-[90%] @950:w-[850px] m-auto'>
                <h1 className='text-[40px] sm:text-5xl font-poppins font-semibold text-center text-slate-900 '>How it Works</h1>
                <p className='font-poppins text-center text-sm leading-6 sm:text-base text-slate-900 mt-3 sm:leading-8'>Whether you need your existing data transferred or you'd like to start afresh, our team of experts will cater to your needs and ensure you are satisfied.</p>
           </div>
           <div className='w-[95%] mt-[40px] mx-auto flex justify-center flex-wrap gap-10'>
                 <div className='group flex items-center gap-3 flex-col'>
                       <div className="p-2 rounded-full w-9 h-9 flex justify-center items-center border group-hover:bg-cyan-800">
                             <h1 className='text-cyan-900 group-hover:text-white text-sm font-poppins'>1</h1>
                       </div>
                       <div>
                            <div className='w-[70px] h-[70px] shadow-simple-shadow rounded-full p-3 bg-white m-auto'>
                                  <img src={serverImage} className="" alt=""/>
                            </div>
                            <h3 className='text-center font-poppins text-sm sm:text-base text-slate-900 mt-2'>We set up your Server</h3>
                       </div>
                 </div>
                 <div className='group flex items-center gap-3 flex-col'>
                       <div className="p-2 rounded-full w-9 h-9 flex justify-center items-center border group-hover:bg-cyan-800">
                             <h1 className='text-cyan-900 group-hover:text-white text-sm font-poppins'>2</h1>
                       </div>
                       <div>
                            <div className='w-[70px] h-[70px] shadow-simple-shadow rounded-full p-3 bg-white m-auto'>
                                  <img src={trainingImage} className="" alt=""/>
                            </div>
                            <h3 className='text-center font-poppins text-sm sm:text-base text-slate-900 mt-2'>We train your people</h3>
                       </div>
                 </div>
                 <div className='group flex items-center gap-3 flex-col'>
                       <div className="p-2 rounded-full w-9 h-9 flex justify-center items-center border group-hover:bg-cyan-800">
                             <h1 className='text-cyan-900 group-hover:text-white text-sm font-poppins'>3</h1>
                       </div>
                       <div>
                            <div className='w-[70px] h-[70px] shadow-simple-shadow rounded-full p-3 bg-white m-auto'>
                                  <img src={moveImage} className="" alt=""/>
                            </div>
                            <h3 className='text-center font-poppins text-sm sm:text-base text-slate-900 mt-2'>We transfer your data(If needed)</h3>
                       </div>
                 </div>
                 <div className='group flex items-center gap-3 flex-col'>
                       <div className="p-2 rounded-full w-9 h-9 flex justify-center items-center border group-hover:bg-cyan-800">
                             <h1 className='text-cyan-900 group-hover:text-white text-sm font-poppins'>4</h1>
                       </div>
                       <div>
                            <div className='w-[70px] h-[70px] shadow-simple-shadow rounded-full p-3 bg-white m-auto'>
                                  <img src={subscribeImage} className="" alt=""/>
                            </div>
                            <h3 className='text-center font-poppins text-sm sm:text-base text-slate-900 mt-2'>You pay the Subscription</h3>
                       </div>
                 </div>
                 <div className='group flex items-center gap-3 flex-col'>
                       <div className="p-2 rounded-full w-9 h-9 flex justify-center items-center border group-hover:bg-cyan-800">
                             <h1 className='text-cyan-900 text-sm font-poppins group-hover:text-white'>5</h1>
                       </div>
                       <div>
                            <div className='w-[70px] h-[70px] shadow-simple-shadow rounded-full p-3 bg-white m-auto'>
                                  <img src={configureImage} className="" alt=""/>
                            </div>
                            <h3 className='text-center font-poppins text-sm sm:text-base text-slate-900 mt-2'>We help configure your shop</h3>
                       </div>
                 </div>
                 <div className='group flex items-center gap-3 flex-col'>
                       <div className="p-2 rounded-full w-9 h-9 flex justify-center items-center border group-hover:bg-cyan-800">
                             <h1 className='text-cyan-900 group-hover:text-white text-sm font-poppins'>6</h1>
                       </div>
                       <div>
                            <div className='w-[70px] h-[70px] shadow-simple-shadow rounded-full p-3 bg-white m-auto'>
                                  <img src={supportImage} className="" alt=""/>
                            </div>
                            <h3 className='text-center font-poppins text-sm sm:text-base text-slate-900 mt-2'>We set up your support line</h3>
                       </div>
                 </div>
           </div>
    </div>
  )
}

export default HowSection