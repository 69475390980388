import React from 'react';
import Benefits from './components/Benefits.jsx';
import ContactSection from './components/ContactSection.jsx';
import Features from './components/Features.jsx';
import Footer from './components/Footer.jsx';
import HeroSection from './components/HeroSection.jsx';
import HowSection from './components/HowSection.jsx';
import Modules from './components/Modules.jsx';
import Navbar from './components/Navbar.jsx';

function App() {
  return (
    <div className="h-[100vh] w-full">
         <Navbar />
         <HeroSection />
         <Benefits />
         <Features />
         <Modules />
         <HowSection />
         <Footer />
    </div>
  );
}

export default App;
