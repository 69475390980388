import React, { useState } from "react";
import { Link } from "react-scroll";
import logo from "../assets/aboss-logo-2.png";

const Navbar = () => {
  let [open, setOpen] = useState(false);
  let [colorOnScroll, setColorOnScroll] = useState(false);

  window.addEventListener("scroll", () => {
    if (window.scrollY >= 60) {
      setColorOnScroll(true);
    } else {
      setColorOnScroll(false);
    }
  });

  return (
    <div
      className={`fixed top-0 w-full h-auto @950:h-[85px]  bg-white z-[99]  ${
        colorOnScroll ? "shadow-lower-shadow" : "shadow-no-shadow"
      } `}
    >
      <div className="relative w-full @950:max-w-[90%] mx-auto @950:px-4 @950:flex @950:justify-between @950:items-center">
        <div
          onClick={() => setOpen(!open)}
          className="absolute right-8 top-6 cursor-pointer @950:hidden"
        >
          <ion-icon name={open ? "close" : "menu"} size="large"></ion-icon>
        </div>
        <div className="w-48 sm:w-[240px] h-[85px] flex justify-center items-center ml-4 @950:ml-0">
          <img src={logo} alt="" className="w-full h-3/4 object-fill" />
        </div>
        <nav
          className={`absolute bg-white px-10 w-full flex-col @950:static flex @950:gap-24 @950:flex-row @950:w-max @950:px-10 font-poppins @950:items-center pb-3 gap-3 @950:pb-0 @950:z-auto z-[-4] transition-all duration-700 ease-in ${
            open ? "top-[85px]" : "top-[-450px]"
          }`}
        >
          <ul className="flex flex-col gap-5 @950:gap-10 @950:flex-row ">
            <li>
              <Link
                to="hero"
                spy={true}
                smooth={true}
                offset={-100}
                duration={1000}
                className="nav-item relative text-sm py-2 text-slate-900 hover:text-cyan-900 cursor-pointer"
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                to="benefits"
                spy={true}
                smooth={true}
                offset={50}
                duration={1000}
                className="nav-item relative text-sm py-2 hover:text-cyan-900 cursor-pointer text-slate-900 "
              >
                Benefits
              </Link>
            </li>
            <li>
              <Link
                to="features"
                spy={true}
                smooth={true}
                offset={-50}
                duration={1000}
                className="nav-item relative text-sm py-2 hover:text-cyan-900 cursor-pointer text-slate-900 "
              >
                Features
              </Link>
            </li>
          </ul>
          <div>
            <a
              href="mailto:sales@vocanter.com"
              className="cursor-pointer flex w-32 h-11  text-sm justify-center items-center rounded text-white bg-cyan-800 hover:bg-cyan-700"
            >
              Contact Us
            </a>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Navbar;
