import React from 'react'
import iconshop from '../assets/supply-chain.png'
import iconmanage from '../assets/waste.png'
import iconreports from '../assets/behavior.png'
import iconservice from '../assets/process.png'
import iconconclusive from '../assets/report.png'
import iconbranches from '../assets/branch.png'

const Benefits = () => {
  return (
    <div id='benefits' className='pt-[10%] @950:pt-48 pb-20 bg-cyan-800/5'>
           <div className='w-[90%] @950:w-[850px] m-auto'>
                <h1 className='text-[40px] sm:text-5xl font-poppins font-semibold text-center text-slate-900 '>What Can Aboss do for you</h1>
                <p className='font-poppins text-center text-sm leading-6 sm:text-lg text-slate-900  mt-3 sm:leading-8'>We partner with independent auto repair shops of all sizes to help them achieve their goals. Our tools and auto repair shop management services are tailored to your needs and goals to help you see real value and real success.</p>
           </div>
           <div className='w-[90%] mx-auto my-10 flex gap-10 justify-center flex-wrap'>
                <div className="w-[350px] p-6 bg-white rounded-xl hover:shadow-simple-shadow transition-all duration-500 ease-in ">
                      <div className="w-[80px] h-[80px]">
                           <img src={iconshop} alt="" />
                      </div>
                      <h2 className='mt-10 font-poppins text-slate-900 font-medium text-[18px] sm:text-[20px]'>Professional Shop Manager</h2>
                      <p className='font-poppins text-sm sm:text-[15px] text-slate-900  mt-2'>Single high level view of your business to understand performance relative to the past</p>
                </div>
                <div className="w-[350px] p-6 bg-white rounded-xl hover:shadow-simple-shadow transition-all duration-500 ease-in">
                      <div className="w-[80px] h-[80px]">
                           <img src={iconmanage} alt="" />
                      </div>
                      <h2 className='mt-10 font-poppins font-medium text-slate-900 text-[18px] sm:text-[20px]'>Manage Vehicles & Owner Information</h2>
                      <p className='font-poppins text-slate-900 text-sm sm:text-[15px] mt-2'>Capture detailed Information of every vehicle to truly know your customer</p>
                </div>
                <div className="w-[350px] p-6 bg-white rounded-xl hover:shadow-simple-shadow transition-all duration-500 ease-in">
                      <div className="w-[80px] h-[80px]">
                           <img src={iconreports} alt="" />
                      </div>
                      <h2 className='mt-10 font-poppins text-slate-900 font-medium text-[18px] sm:text-[20px]'>Track Jobs & Create Reports</h2>
                      <p className='font-poppins text-slate-900 text-sm sm:text-[15px] mt-2'>Maintain the entire history of a vehicle's maintenance, capture, receive and discard records</p>
                </div>
                <div className="w-[350px] p-6 bg-white rounded-xl hover:shadow-simple-shadow transition-all duration-500 ease-in">
                      <div className="w-[80px] h-[80px]">
                           <img src={iconservice} alt="" />
                      </div>
                      <h2 className='mt-10 font-poppins text-slate-900 font-medium text-[18px] sm:text-[20px]'>Service Feedback</h2>
                      <p className='font-poppins text-slate-900 text-sm sm:text-[15px] mt-2'>Manage your customers' expectations and respond to concerns effectively.</p>
                </div>
                <div className="w-[350px] p-6 bg-white rounded-xl hover:shadow-simple-shadow transition-all duration-500 ease-in">
                      <div className="w-[80px] h-[80px]">
                           <img src={iconconclusive} alt="" />
                      </div>
                      <h2 className='mt-10 font-poppins text-[18px] font-medium text-slate-900 sm:text-[20px]'>Manage Defects, Parts and Services</h2>
                      <p className='font-poppins text-sm sm:text-[15px] text-slate-900 mt-2'>Order parts, services and track defects, use digital process to requisition new parts</p>
                </div>
                <div className="w-[350px] p-6 bg-white rounded-xl hover:shadow-simple-shadow transition-all duration-500 ease-in">
                      <div className="w-[80px] h-[80px]">
                           <img src={iconbranches} alt="" />
                      </div>
                      <h2 className='mt-10 font-poppins text-slate-900 font-medium text-[18px] sm:text-[20px]'>Run 1 or more branches</h2>
                      <p className='font-poppins text-slate-900 text-sm sm:text-[15px] mt-2'>Have a common setting for the whole company, and manage branches independently.</p>
                </div>
           </div>
    </div>
  )
}

export default Benefits