import React from 'react'
import solutionsImage from '../assets/hero-bg2.jpg';

const Features = () => {
  return (
    <div id='features' className='py-[80px] bg-white'>
           <div className='w-[90%] @950:w-[850px] m-auto'>
                 <h1 className='text-[40px] sm:text-5xl font-poppins font-semibold text-center sm:leading-[60px] text-slate-900 '>Optimize your Autoshop Operations with a Great Software Solution</h1>
                 <p className='font-poppins text-center text-sm leading-6 sm:text-base mt-3 sm:leading-8 text-slate-900 '>Compete with top auto repair shops in your area and optimize your team to work like a well-oild machine. A-BOSS has a toolbox with everything your shop needs to work efficiently and effectively</p>
           </div>
           <div className='grid grid-cols-1 @950:grid-cols-2 mt-2 sm:mt-10'>
                 <div className='p-10'>
                       <h2 className='text-center sm:text-left text-4xl font-poppins font-normal uppercase'>Key Features</h2>
                       <ul className='mt-5 flex flex-col gap-5'>
                            <li>
                                <div className='flex items-center gap-2 flex-col sm:flex-row'>
                                      <span className='text-[50px] text-cyan-800'><ion-icon name="shield-checkmark-outline"></ion-icon></span>
                                      <div className="w-full text-center sm:w-4/5 sm:text-left">
                                            <h2 className='font-poppins font-normal text-xl text-slate-900 '>Server Cloud-Based Solution</h2>
                                            <p className='font-poppins text-sm leading-7 text-slate-900 '>Your data is stored on the cloud enabling you to have continuous operations no matter how your physical location is impacted.</p>
                                     </div>
                                </div>
                            </li>
                             <li>
                                <div className='flex items-center gap-2 flex-col sm:flex-row'>
                                      <span className='text-[50px] text-cyan-800'><ion-icon name="shield-checkmark-outline"></ion-icon></span>
                                      <div className="w-full text-center sm:w-4/5 sm:text-left">
                                            <h2 className='font-poppins font-normal text-xl text-slate-900 '>Automatic Data Backups</h2>
                                            <p className='font-poppins text-sm leading-7 text-slate-900 '>A-Boss data is backed up daily giving you peace of mind when running your shop</p>
                                     </div>
                                </div>
                            </li>
                            <li>
                                <div className='flex items-center gap-2 flex-col sm:flex-row'>
                                      <span className='text-[50px] text-cyan-800'><ion-icon name="shield-checkmark-outline"></ion-icon></span>
                                      <div className="w-full text-center sm:w-4/5 sm:text-left">
                                            <h2 className='font-poppins font-normal text-xl text-slate-900 '>Local and International Support times</h2>
                                            <p className='font-poppins text-sm leading-7 text-slate-900 '>A team located in your region will work with you from training to ensure your needs are met effectively and efficiently</p>
                                     </div>
                                 </div>
                            </li>
                            <li>
                               <div className='flex items-center gap-2 flex-col sm:flex-row'>
                                      <span className='text-[50px] text-cyan-800'><ion-icon name="shield-checkmark-outline"></ion-icon></span>
                                      <div className="w-full text-center sm:w-4/5 sm:text-left">
                                            <h2 className='font-poppins font-normal text-xl text-slate-900 '>Input into your Solution</h2>
                                            <p className='font-poppins text-sm leading-7 text-slate-900 '>Our informative webinars and summit meetings are great ways for us to learn what you want to see in the tool and a way for you to impact the upcoming releases.</p>
                                     </div>
                                </div>
                            </li>
                            <li>
                               <div className='flex items-center gap-2 flex-col sm:flex-row'>
                                      <span className='text-[50px] text-cyan-800'><ion-icon name="shield-checkmark-outline"></ion-icon></span>
                                      <div className="w-full text-center sm:w-4/5 sm:text-left">
                                            <h2 className='font-poppins font-normal text-xl text-slate-900 '>History/Data Migration & Analysis</h2>
                                            <p className='font-poppins text-sm leading-7 text-slate-900 '>We work with your team to analyze and migrate your current data into A-Boss. This could be data stored in spreadsheets, mobile phone contacts, or other database systems.</p>
                                     </div>
                                </div>
                            </li>
                       </ul>
                       
                 </div>
                 <div className='@950:rounded-tl-3xl @950:rounded-bl-3xl shadow-image-shadow overflow-hidden'>
                       <img src={solutionsImage} alt="" className='h-full object-cover' />
                 </div>
           </div>
    </div>
  )
}

export default Features