import React from 'react'
import { Link } from 'react-scroll'
import heroImage from '../assets/dummy-1.png';


function HeroSection() {
  return (
    <div id="hero" className='w-full h-[100vh] mt-[87px] relative'>
          <div className='w-[90%] m-auto pt-20 border-solid border-t border-blue-50 flex flex-col gap-10'>
                <div className="w-full @950:w-[850px] m-auto text-center">
                       <h1 className='text-[40px] sm:text-5xl font-poppins font-semibold mb-3 text-slate-900 '>Reinventing the Autoshop</h1>
                       <p className='text-sm leading-6 sm:text-lg font-poppins text-slate-900 '>Our software stack enables auto repair shops establish a true knowledge based relationship with their customers by giving our customers the tools to know and accurately assess the needs of motor vehicle owners</p>
                       <div className='mt-5 flex justify-center gap-4 flex-col items-center sm:flex-row'>
                            <Link to="benefits" spy={true} smooth={true} offset={50} duration={1000} className="w-[150px] h-[45px] text-sm font-poppins rounded-md bg-cyan-900 text-white flex justify-center items-center cursor-pointer hover:text-cyan-900 hover:bg-white hover:border-2 hover:border-cyan-800 hover:border-solid">Learn More</Link>
                            <Link to="features" spy={true} smooth={true} offset={-80} duration={1000} className="w-[150px] h-[45px] font-poppins text-slate-900 rounded-md text-sm border-solid border-2 border-cyan-800 flex justify-center items-center hover:bg-cyan-900 hover:text-white cursor-pointer hover:border-none">Features</Link>
                       </div>
                </div>
                <div className='w-full @950:w-[80%] @950:h-[500px] m-auto'>
                      <img src={heroImage} alt="" className='w-[80%] h-[100%] m-auto '/>
                </div>
          </div>
    </div>
  )
}

export default HeroSection