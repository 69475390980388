import React from "react";
import footerLogo from "../assets/aboss-white-logo-2.png";

const Footer = () => {
  let current_year = new Date().getFullYear();

  return (
    <div className="py-[60px] bg-[#033245]">
      <div>
        <div className="flex justify-center">
          <div className="w-48 sm:w-[250px] h-[85px] flex justify-center items-center ">
            <img src={footerLogo} alt="" className="w-full h-3/4 object-fill" />
          </div>
        </div>
        <p className="w-[90%] text-center font-poppins my-3 text-white text-sm @950:w-[800px] mx-auto leading-8">
          Reimagining Motor Repair Service Delivery for the 21st Century. Since
          our founding in 2005, we help our customers unlock the potential of
          new and expanded revenue through better information, experiences and
          automation of their processes.
        </p>
        <div className="w-[90%] sm:w-[320px] @600:w-[90%] mx-auto my-10 @600:ml-[5%] flex justify-center flex-col gap-5 @600:flex-row @600:gap-10">
          <div className="flex items-center gap-2">
            <span className="w-[45px] h-[45px] flex items-center justify-center rounded-full bg-sky-100 text-xl text-cyan-900">
              <ion-icon name="call"></ion-icon>
            </span>
            <div>
              <p className="font-poppins text-sm sm:text-base text-white font-light">
                Call Us
              </p>
              <h2 className="font-poppins text-sm sm:text-base text-white">
                +254 793 894 475
              </h2>
              <h2 className="font-poppins text-sm sm:text-base text-white">
                +254 759 431 765
              </h2>
            </div>
          </div>
          <div className="flex items-center gap-2">
            <span className="w-[45px] h-[45px] flex items-center justify-center rounded-full bg-sky-100 text-xl text-cyan-900">
              <ion-icon name="mail"></ion-icon>
            </span>
            <div>
              <p className="font-poppins text-sm sm:text-base text-white font-light">
                Email Us
              </p>
              <h2 className="font-poppins text-sm sm:text-base text-white">
                sales@vocanter.com
              </h2>
            </div>
          </div>
        </div>

        {/* <div>
          <h2 className="font-poppins text-white text-center text-sm sm:text-lg">
            Connect with Us on social Media
          </h2>
          <ul className="flex gap-7 text-white justify-center mt-3 flex-wrap">
            <li className="group w-[45px] h-[45px] border rounded-full hover:bg-white hover:border-none">
              <a
                className="flex h-full  w-full items-center justify-center transition-all duration-700 ease-in-out group-hover:text-cyan-900"
                href="x"
              >
                <span>
                  <ion-icon name="logo-facebook"></ion-icon>
                </span>
              </a>
            </li>
            <li className="group w-[45px] h-[45px] border rounded-full hover:bg-white hover:border-none">
              <a
                className="flex h-full  w-full items-center justify-center transition-all duration-700 ease-in-out group-hover:text-cyan-900"
                href="x"
              >
                <span>
                  <ion-icon name="logo-twitter"></ion-icon>
                </span>
              </a>
            </li>
            <li className="group w-[45px] h-[45px] border rounded-full hover:bg-white hover:border-none">
              <a
                className="flex h-full  w-full items-center justify-center transition-all duration-700 ease-in-out group-hover:text-cyan-900"
                href="x"
              >
                <span>
                  <ion-icon name="logo-instagram"></ion-icon>
                </span>
              </a>
            </li>
            <li className="group w-[45px] h-[45px] border rounded-full hover:bg-white hover:border-none">
              <a
                className="flex h-full  w-full items-center justify-center transition-all duration-700 ease-in-out group-hover:text-cyan-900"
                href="x"
              >
                <span>
                  <ion-icon name="logo-linkedin"></ion-icon>
                </span>
              </a>
            </li>
          </ul>
        </div> */}

        <div className="mt-10 before:content before:w-[90%] before:h-[1px] before:block before:bg-slate-100/40 before:m-auto">
          <p className="pt-4 text-center font-poppins text-[11px] text-white/80 font-light">
            All Rights Reserved &copy; {current_year} ABOSS{" "}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
