import React from 'react'

const Modules = () => {
  return (
    <div id='modules' className="pb-[80px] pt-[0px] sm:pt-[40px]">
           <div className='w-[90%] @950:w-[850px] m-auto'>
                <h1 className='text-[40px] sm:text-5xl font-poppins font-semibold text-center sm:leading-[60px] text-slate-900 '>All Inclusive Modules for your business</h1>
           </div>
           <div className='mt-[30px] w-[95%] m-auto flex gap-5 flex-wrap justify-center'>
                <div className='group p-5 border w-full sm:w-[300px] h-auto rounded-md shadow-simple-shadow hover:bg-cyan-900 transition-all duration-600 ease-in'>
                      <h2 className='font-poppins text-sm sm:text-[16px] text-slate-900 group-hover:text-white'>Organization and Branch Management</h2>

                      <div>
                           <span className='text-cyan-900 text-[35px] group-hover:text-white'><ion-icon name="business"></ion-icon></span>
                      </div>
                </div>
                <div className='group p-5 border w-full sm:w-[300px] h-auto rounded-md shadow-simple-shadow hover:bg-cyan-900 transition-all duration-600 ease-in'>
                      <h2 className='font-poppins text-sm sm:text-[16px] text-slate-900 group-hover:text-white'>Workshop Management System</h2>

                      <div>
                           <span className='text-cyan-900 text-[35px] group-hover:text-white'><ion-icon name="car-sport"></ion-icon></span>
                      </div>
                </div>
                <div className='group p-5 border w-full sm:w-[300px] h-auto rounded-md shadow-simple-shadow hover:bg-cyan-900 transition-all duration-600 ease-in'>
                      <h2 className='font-poppins text-sm sm:text-[16px] text-slate-900 group-hover:text-white'>Client Account Management</h2>

                      <div>
                           <span className='text-cyan-900 text-[35px] group-hover:text-white'><ion-icon name="people"></ion-icon></span>
                      </div>
                </div>
                <div className='group p-5 border w-full sm:w-[300px] h-auto rounded-md shadow-simple-shadow hover:bg-cyan-900 transition-all duration-600 ease-in'>
                      <h2 className='font-poppins text-sm sm:text-[16px] text-slate-900 group-hover:text-white'>Vehicle Management</h2>

                      <div>
                           <span className='text-cyan-900 text-[35px] group-hover:text-white'><ion-icon name="construct"></ion-icon></span>
                      </div>
                </div>
                <div className='group p-5 border w-full sm:w-[300px] h-auto rounded-md shadow-simple-shadow hover:bg-cyan-900 transition-all duration-600 ease-in'>
                      <h2 className='font-poppins text-sm sm:text-[16px] text-slate-900 group-hover:text-white'>Job Management</h2>

                      <div>
                           <span className='text-cyan-900 text-[35px] group-hover:text-white'><ion-icon name="calendar"></ion-icon></span>
                      </div>
                </div>
                <div className='group p-5 border w-full sm:w-[300px] h-auto rounded-md shadow-simple-shadow hover:bg-cyan-900  transition-all duration-600 ease-in'>
                      <h2 className='font-poppins text-sm sm:text-[16px] text-slate-900 group-hover:text-white'>Client Communications & Outreach</h2>

                      <div>
                           <span className='text-cyan-900 text-[35px] group-hover:text-white'><ion-icon name="chatbubbles"></ion-icon></span>
                      </div>
                </div>
                <div className='group p-5 border w-full sm:w-[300px] h-auto rounded-md shadow-simple-shadow hover:bg-cyan-900 transition-all duration-600 ease-in'>
                      <h2 className='font-poppins text-sm sm:text-[16px] text-slate-900 group-hover:text-white'>Reporting(Dashboard, Client retention tracker, etc..)</h2>

                      <div>
                           <span className='text-cyan-900 text-[35px] group-hover:text-white'><ion-icon name="albums"></ion-icon></span>
                      </div>
                </div>
                <div className='group p-5 border w-full sm:w-[300px] h-auto rounded-md shadow-simple-shadow hover:bg-cyan-900 transition-all duration-600 ease-in'>
                      <h2 className='font-poppins text-sm sm:text-[16px] text-slate-900 group-hover:text-white'>Defect Management</h2>

                      <div>
                           <span className='text-cyan-900 text-[35px] group-hover:text-white'><ion-icon name="speedometer"></ion-icon></span>
                      </div>
                </div>
                <div className='group p-5 border w-full sm:w-[300px] h-auto rounded-md shadow-simple-shadow hover:bg-cyan-900  transition-all duration-600 ease-in'>
                      <h2 className='font-poppins text-sm sm:text-[16px] text-slate-900 group-hover:text-white'>Finances(Quotes, Invoicing, Receipting)</h2>

                      <div>
                           <span className='text-cyan-900 text-[35px] group-hover:text-white'><ion-icon name="cash"></ion-icon></span>
                      </div>
                </div>
                <div className='group p-5 border w-full sm:w-[300px] h-auto rounded-md shadow-simple-shadow hover:bg-cyan-900  transition-all duration-600 ease-in'>
                      <h2 className='font-poppins text-sm sm:text-[16px] text-slate-900 group-hover:text-white'>Procurement Management</h2>

                      <div>
                           <span className='text-cyan-900 text-[35px] group-hover:text-white'><ion-icon name="receipt"></ion-icon></span>
                      </div>
                </div>
                <div className='group p-5 border w-full sm:w-[300px] h-auto rounded-md shadow-simple-shadow hover:bg-cyan-900 hover:text-white transition-all duration-600 ease-in'>
                      <h2 className='font-poppins text-sm sm:text-[16px] text-slate-900 group-hover:text-white'>Inventory/Stockroom Management</h2>

                      <div>
                           <span className='text-cyan-900 text-[35px] group-hover:text-white'><ion-icon name="clipboard"></ion-icon></span>
                      </div>
                </div>
           </div>
    </div>
  )
}

export default Modules